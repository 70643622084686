export const PlaceholderFilters = () => {
  const fixedWidths = [80, 90, 100, 110, 120] // 5 fixed widths in pixels

  return (
    <div>
      <div className='flex justify-between gap-8'>
        <div className='flex flex-wrap gap-8'>
          {/* Placeholder category buttons */}
          {fixedWidths.map((width, index) => (
            <div
              key={index}
              className='h-7 bg-gray-200 rounded-md'
              style={{ width: `${width}px` }}
            />
          ))}
        </div>
        {/* Placeholder filter button */}
        <div className='py-2 px-5 border flex self-start items-center gap-2 rounded-full bg-gray-50'>
          <div className='bg-gray-200 h-5 w-5 rounded-sm' />
          <div className='bg-gray-200 h-5 w-16 rounded-sm' />
        </div>
      </div>
    </div>
  )
}
