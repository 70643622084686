import style from 'styled-jsx/style'

export const PlaceholderCard = () => {
  return (
    <div className='flex flex-col rounded-lg overflow-hidden h-full border-mischka border-1'>
      <div
        className='relative flex-shrink-0 text-0'
        style={{ aspectRatio: '16 / 9' }}
      >
        <div className='absolute top-4 right-4 flex gap-2'>
          {/* Placeholder for 'Featured' or 'Coming Soon' tags */}
          <div className='bg-gray-200 rounded-full h-6 w-24' />
        </div>
        {/* Placeholder for Image */}
        <div className='w-full bg-gray-300' style={{ height: '100%' }} />
      </div>
      <div className='flex-1 p-6 flex flex-col justify-between'>
        <div className='flex flex-col gap-4 flex-1'>
          {/* Placeholder for categories */}
          <div className='flex flex-wrap gap-2'>
            <div className='bg-gray-200 h-6 w-20 rounded-md' />
            <div className='bg-gray-200 h-6 w-24 rounded-md' />
          </div>

          {/* Placeholder for Title */}
          <div className='bg-gray-200 h-8 w-3/4 rounded' />

          {/* Placeholder for Provider */}
          <div className='bg-gray-200 h-5 w-1/4 rounded' />

          {/* Placeholder for Timeframe & Hours per week */}
          <div className='flex'>
            <div className='flex items-center'>
              {/* Circle for icon */}
              <div className='bg-gray-200 rounded-full h-5 w-5' />
              <div className='bg-gray-200 h-5 w-16 ml-2 rounded' />
            </div>
            <div className='flex items-center ml-4'>
              {/* Circle for icon */}
              <div className='bg-gray-200 rounded-full h-5 w-5' />
              <div className='bg-gray-200 h-5 w-20 ml-2 rounded' />
            </div>
          </div>

          {/* Placeholder for Price */}
          <div className='bg-gray-200 h-6 w-1/4 rounded' />

          {/* Placeholder for CTA (Arrow icon) */}
          <div className='flex justify-end'>
            <div className='bg-gray-200 rounded-full h-9 w-9' />
          </div>
        </div>
      </div>
    </div>
  )
}
