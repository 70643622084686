export type FilterState = {
  selectedCategories: string[]
  selectedFormats: string[]
  durationMinMax: [number, number]
  durationRange: [number, number]
  search: string
}

// Initial state for the filter
export const initialState: FilterState = {
  selectedCategories: [],
  selectedFormats: [],
  durationMinMax: [0, 12],
  durationRange: [0, 12],
  search: '',
}

export type FilterAction =
  | { type: 'TOGGLE_CATEGORY'; payload: string }
  | { type: 'TOGGLE_FORMAT'; payload: string }
  | { type: 'UPDATE_MIN_MAX_DURATION'; payload: [number, number] }
  | { type: 'UPDATE_DURATION'; payload: [number, number] }
  | { type: 'UPDATE_SEARCH'; payload: string }
  | { type: 'CLEAR_FILTERS' }

// Reducer function to handle filter state updates
export const filterReducer = (state: FilterState, action: FilterAction) => {
  switch (action.type) {
    case 'TOGGLE_CATEGORY':
      return {
        ...state,
        selectedCategories: state.selectedCategories.includes(action.payload)
          ? state.selectedCategories.filter((cat) => cat !== action.payload)
          : [...state.selectedCategories, action.payload],
      }
    case 'TOGGLE_FORMAT':
      return {
        ...state,
        selectedFormats: state.selectedFormats.includes(action.payload)
          ? state.selectedFormats.filter((fmt) => fmt !== action.payload)
          : [...state.selectedFormats, action.payload],
      }
    case 'UPDATE_MIN_MAX_DURATION':
      return {
        ...state,
        durationMinMax: action.payload,
      }
    case 'UPDATE_DURATION':
      return {
        ...state,
        durationRange: action.payload, // this should update the selected range
      }
    case 'UPDATE_SEARCH':
      return {
        ...state,
        search: action.payload,
      }
    case 'CLEAR_FILTERS':
      return initialState // Reset to initial state
    default:
      return state
  }
}
