import { useQuery } from '@apollo/client'
import CourseCard from 'components/CourseCard/CourseCard'
import { CoursesFilters } from 'components/CoursesFilters/CoursesFilters'
import { PlaceholderCard } from 'components/PlaceholderCard/PlaceholderCard'
import { AnimatePresence, motion } from 'framer-motion'
import { type CoursesData, GET_PUBLIC_COURSES } from 'gql/courses'
import { filterReducer, initialState } from 'hooks/useFilterReducer'
import { useReducer, useState } from 'react'

const ANIMATION_DURATION = 0.5

type CoursesProps = {
  className?: string
}

export const Courses = ({ className }: CoursesProps) => {
  const { data, loading, error } = useQuery<CoursesData>(GET_PUBLIC_COURSES)
  const { courses } = data || { courses: [] }
  // Filter state and dispatch using useReducer
  const [filterState, dispatch] = useReducer(filterReducer, initialState)

  // Filtering logic that filters the courses based on the selected filters
  let filtered = courses

  // Filter by search term. Filter by title and overview
  if (filterState.search) {
    filtered = filtered.filter(
      (course) =>
        course.title.toLowerCase().includes(filterState.search.toLowerCase()) ||
        course?.overview
          ?.toLowerCase()
          .includes(filterState.search.toLowerCase()),
    )
  }

  // Filter by selected categories
  if (filterState.selectedCategories.length > 0) {
    filtered = filtered.filter((course) =>
      course.categories.some((category) =>
        filterState.selectedCategories.includes(String(category.id)),
      ),
    )
  }

  // Filter by selected formats
  if (filterState.selectedFormats.length > 0) {
    filtered = filtered.filter((course) => {
      if (
        (filterState.selectedFormats.includes('Free') && course.isFree) ||
        (filterState.selectedFormats.includes('Paid') && !course.isFree) ||
        (filterState.selectedFormats.includes('Samplers') &&
          course.type === 'FREE_COURSE') ||
        (filterState.selectedFormats.includes('Short courses') &&
          course.type === 'SHORT_COURSE') ||
        (filterState.selectedFormats.includes('Micro credentials') &&
          course.type === 'MICROCREDENTIAL')
      ) {
        return true // Keep the course if it matches the selected format
      }
      return false // Exclude the course if it doesn't match the selected format
    })
  }

  // Filter by duration range
  if (filtered.length > 0) {
    filtered = filtered.filter(
      (course) =>
        Number(course.timeframeWeeks) >= filterState.durationRange[0] &&
        Number(course.timeframeWeeks) <= filterState.durationRange[1],
    )
  }

  // Sort courses by timeframeWeeks, ensuring it's a number
  const sortedCourses = filtered.slice().sort((a, b) => {
    const timeframeA = Number(a.timeframeWeeks) || 0 // Ensure it's a number or use 0 as fallback
    const timeframeB = Number(b.timeframeWeeks) || 0 // Ensure it's a number or use 0 as fallback
    return timeframeA - timeframeB
  })

  // Variants for the containers and cards
  const containerVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: ANIMATION_DURATION } },
    exit: { opacity: 0, transition: { duration: ANIMATION_DURATION } },
  }

  const cardVariants = {
    hidden: { opacity: 0 },
    visible: { opacity: 1, transition: { duration: ANIMATION_DURATION } },
    exit: { opacity: 0, transition: { duration: ANIMATION_DURATION } },
  }

  return (
    <div className={className}>
      <CoursesFilters
        state={filterState}
        dispatch={dispatch}
        courses={courses}
        loading={loading}
      />
      <AnimatePresence exitBeforeEnter>
        {loading ? (
          <motion.div
            key='loading'
            className='grid grid-cols-1 gap-5 md:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 pt-6'
            initial={{ opacity: 1 }}
            exit={{ opacity: 0 }}
            transition={{ duration: ANIMATION_DURATION }}
          >
            {Array.from({ length: 8 }).map((_, i) => (
              <motion.div
                key={i}
                initial={{ opacity: 1 }}
                exit={{ opacity: 0 }}
                transition={{ duration: ANIMATION_DURATION }}
              >
                <PlaceholderCard />
              </motion.div>
            ))}
          </motion.div>
        ) : (
          <motion.div
            key='loaded'
            className='grid grid-cols-1 sm:grid-cols-2 md:grid-cols-1 gap-5 lg:grid-cols-2 xl:grid-cols-3 2xl:grid-cols-4 pt-6'
            variants={containerVariants}
            initial='hidden'
            animate='visible'
            exit='exit'
          >
            {sortedCourses.length > 0 ? (
              sortedCourses.map((course) => (
                <motion.div key={`course-${course.id}`} variants={cardVariants}>
                  <CourseCard course={course} />
                </motion.div>
              ))
            ) : (
              <p>No courses found matching the selected filters.</p>
            )}
          </motion.div>
        )}
      </AnimatePresence>
    </div>
  )
}
