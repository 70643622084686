import Button from 'components/Button'
import { CategoryLabel } from 'components/CategoryLabel/CategoryLabel'
import { PlaceholderFilters } from 'components/PlaceholderFilters/PlaceholderFilters'
import { motion } from 'framer-motion'
import type { FilterAction, FilterState } from 'hooks/useFilterReducer'
import Image from 'next/image'
import React, {
  useMemo,
  useState,
  useRef,
  useEffect,
  type Dispatch,
} from 'react'
import ReactSlider from 'react-slider'
import CrossIcon from 'svg/cross.svg'
import HangarIcon from 'svg/door-hangar.svg'
import FiltersIcon from 'svg/filters.svg'
import ControllerIcon from 'svg/game.svg'
import HealthIcon from 'svg/health.svg'
import MaoriIcon from 'svg/maori.svg'
import MoreThanIcon from 'svg/more-than.svg'
import SearchIcon from 'svg/search.svg'
import SoilIcon from 'svg/soil.svg'
import SuitcaseIcon from 'svg/suitcase.svg'
import SustainabilityIcon from 'svg/sustainability.svg'
import type { Category, Course } from 'types/course'

const getAllCategories = (courses: Course[]) => {
  const categoriesList = new Set<Category>()
  courses.forEach((course) => {
    course.categories.forEach((category) => {
      categoriesList.add(category)
    })
  })

  return Array.from(categoriesList)
}

type CourseFiltersProps = {
  state: FilterState
  dispatch: Dispatch<FilterAction>
  courses: Course[]
  loading?: boolean
}

export const CoursesFilters = ({
  state,
  dispatch,
  courses,
  loading = false,
}: CourseFiltersProps) => {
  if (loading) {
    return <PlaceholderFilters />
  }

  // Get all categories from the courses
  const categories = useMemo(() => getAllCategories(courses), [courses])

  const [isVisible, setIsVisible] = useState(false)
  const [scrollPosition, setScrollPosition] = useState(0)
  const [canScrollLeft, setCanScrollLeft] = useState(false)
  const [canScrollRight, setCanScrollRight] = useState(false)
  const scrollContainerRef = useRef<HTMLDivElement>(null)
  const isScrollingRef = useRef(false)
  const animationFrameRef = useRef<number>()

  const formats = [
    'Free',
    'Paid',
    'Samplers',
    'Short courses',
    'Micro credentials',
  ]

  // Remove Icon mapping and getIcon function

  const checkScroll = () => {
    const container = scrollContainerRef.current
    if (container) {
      const scrollLeft = Math.round(container.scrollLeft)
      const maxScroll = Math.round(
        container.scrollWidth - container.clientWidth,
      )

      const newCanScrollLeft = scrollLeft > 0
      const newCanScrollRight = scrollLeft < maxScroll

      setCanScrollLeft(newCanScrollLeft)
      setCanScrollRight(newCanScrollRight)
      setScrollPosition(scrollLeft)
    }
  }

  useEffect(() => {
    const container = scrollContainerRef.current
    if (container) {
      checkScroll()

      const handleScroll = () => {
        if (!isScrollingRef.current) {
          requestAnimationFrame(checkScroll)
        }
      }

      container.addEventListener('scroll', handleScroll, { passive: true })

      const resizeObserver = new ResizeObserver(() => {
        checkScroll()
      })
      resizeObserver.observe(container)

      return () => {
        container.removeEventListener('scroll', handleScroll)
        resizeObserver.disconnect()
        if (animationFrameRef.current) {
          cancelAnimationFrame(animationFrameRef.current)
        }
      }
    }
  }, [])

  const handleScroll = (direction: 'left' | 'right') => {
    const container = scrollContainerRef.current
    if (container && !isScrollingRef.current) {
      isScrollingRef.current = true

      const currentScroll = container.scrollLeft
      const maxScroll = container.scrollWidth - container.clientWidth
      const visibleWidth = container.clientWidth
      const baseScrollAmount = Math.round(visibleWidth * 0.8)

      let targetScroll: number
      if (direction === 'left') {
        const scrollAmount = Math.min(baseScrollAmount, currentScroll)
        targetScroll = currentScroll - scrollAmount
      } else {
        const scrollAmount = Math.min(
          baseScrollAmount,
          maxScroll - currentScroll,
        )
        targetScroll = currentScroll + scrollAmount
      }

      // Set up animation monitoring
      const startTime = performance.now()
      const startScroll = currentScroll
      const scrollDistance = targetScroll - startScroll
      const duration = 300 // match CSS transition duration

      const animate = (currentTime: number) => {
        const elapsed = currentTime - startTime
        const progress = Math.min(elapsed / duration, 1)

        // Ease out cubic function
        const easeProgress = 1 - (1 - progress) ** 3
        const currentPosition = startScroll + scrollDistance * easeProgress

        container.scrollLeft = currentPosition
        checkScroll()

        if (progress < 1) {
          animationFrameRef.current = requestAnimationFrame(animate)
        } else {
          isScrollingRef.current = false
          checkScroll()
        }
      }

      animationFrameRef.current = requestAnimationFrame(animate)
    }
  }

  return (
    <div className='w-[calc(100vw-5rem)] md:w-full mx-auto flex flex-col gap-8'>
      <Button
        theme='secondary'
        size='small'
        onClick={() => setIsVisible(!isVisible)}
        className='gap-2 flex self-end -mt-16 lg:hidden'
      >
        <FiltersIcon />
        Filters
      </Button>
      <div className='flex gap-4 items-center'>
        <Button
          theme='secondary'
          onClick={() => handleScroll('left')}
          className='w-9 h-9 px-0 py-0 flex-shrink-0 hidden md:flex'
          disabled={!canScrollLeft}
        >
          <MoreThanIcon className='w-5 h-5 rotate-180 -ml-1 -mt-0.5' />
        </Button>
        <motion.div
          ref={scrollContainerRef}
          className='w-full relative flex-1 flex-shrink overflow-x-auto md:overflow-x-hidden overflow-y-visible grid grid-flow-col gap-8 pb-2 whitespace-nowrap'
          onScroll={() => {
            if (!isScrollingRef.current) {
              requestAnimationFrame(checkScroll)
            }
          }}
        >
          {categories.length > 0 &&
            categories.map((category) => (
              <button
                className={`relative flex flex-col flex-shrink-0 items-center gap-2 text-comet text-xs ${
                  state.selectedCategories.includes(String(category.id))
                    ? 'before:w-full'
                    : 'before:w-0'
                } before:h-1 before:absolute before:bg-purple-heart before:-bottom-2 before:rounded-t-full before:left-0 before:transform before:transition-all before:duration-300 before:ease-in-out`}
                key={category.id}
                onClick={() =>
                  dispatch({
                    type: 'TOGGLE_CATEGORY',
                    payload: String(category.id),
                  })
                }
              >
                <CategoryLabel category={category} style='menu' />
              </button>
            ))}
        </motion.div>
        <Button
          theme='secondary'
          onClick={() => handleScroll('right')}
          className='w-9 h-9 px-0 py-0 flex-shrink-0 hidden md:flex'
          disabled={!canScrollRight}
        >
          <MoreThanIcon className='w-5 h-5 ml-1 mt-0.5' />
        </Button>
        <Button
          theme='secondary'
          size='small'
          onClick={() => setIsVisible(!isVisible)}
          className='gap-2 hidden lg:flex'
        >
          <FiltersIcon />
          Filters
        </Button>
      </div>

      {/* Sidebar Filters */}
      <div
        className={`fixed overflow-y-auto bg-white right-0 top-0 h-screen z-50 p-6 shadow-lg transition-all ease-in-out duration-300 ${isVisible ? 'translate-x-0' : 'translate-x-full'}`}
      >
        <div className='flex items-center mb-6'>
          <h1 className='font-heading text-3xl flex-1 font-medium'>Filters</h1>
          <button onClick={() => setIsVisible(!isVisible)}>
            <CrossIcon className='w-5 h-5 text-purple-heart' />
          </button>
        </div>

        {/* Full Text Search */}

        <div className='relative flex border border-mischka rounded-full p-1 mb-6'>
          <input
            id='search'
            type='text'
            placeholder='Search by keyword'
            value={state.search}
            onChange={(e) =>
              dispatch({ type: 'UPDATE_SEARCH', payload: e.target.value })
            }
            className='w-full border-none rounded-full focus:ring-2 focus:ring-purple-heart pr-10 placeholder-santas-gray'
          />
          <SearchIcon className='absolute right-4 top-[0.9rem] text-gray-50' />
        </div>

        {/* Categories */}
        <div className='mb-4 pb-4 border-b border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Categories
          </h2>
          {categories.length > 0 &&
            categories.map((category) => (
              <div key={category.id} className='flex items-center py-2'>
                <input
                  id={String(category.id)}
                  type='checkbox'
                  className='cursor-pointer mr-3 w-4 h-4 border border-comet rounded-[4px] hover:bg-mischka hover:checked:bg-purple-heart focus:hover:checked:bg-purple-heart focus:hover:checked:ring-purple-heart checked:bg-purple-heart focus:ring-purple-heart focus:checked:bg-purple-heart'
                  checked={state.selectedCategories.includes(
                    String(category.id),
                  )}
                  onChange={() =>
                    dispatch({
                      type: 'TOGGLE_CATEGORY',
                      payload: String(category.id),
                    })
                  }
                />
                <label
                  htmlFor={String(category.id)}
                  className='cursor-pointer text-comet text-sm font-medium'
                >
                  {category.title}
                </label>
              </div>
            ))}
        </div>

        {/* Formats */}
        <div className='mb-4 pb-4 border-b border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Formats
          </h2>
          {formats.map((format) => (
            <div key={format} className='flex items-center py-2'>
              <input
                id={format}
                type='checkbox'
                className='cursor-pointer mr-3 w-4 h-4 border border-comet rounded-[4px] hover:bg-mischka hover:checked:bg-purple-heart focus:hover:checked:bg-purple-heart focus:hover:checked:ring-purple-heart checked:bg-purple-heart focus:ring-purple-heart focus:checked:bg-purple-heart'
                checked={state.selectedFormats.includes(format)}
                onChange={() =>
                  dispatch({ type: 'TOGGLE_FORMAT', payload: format })
                }
              />
              <label
                htmlFor={format}
                className='cursor-pointer text-comet text-sm font-medium'
              >
                {format}
              </label>
            </div>
          ))}
        </div>

        {/* Duration Slider */}
        <div className='mb-4 pb-4 border-mischka'>
          <h2 className='font-heading font-medium text-xl mb-2 text-cinder'>
            Duration
          </h2>

          <ReactSlider
            className='my-6 ml-7 mr-7 mt-16'
            thumbClassName='bg-white -translate-y-1.5 border-2 border-purple-heart h-4 w-4 rounded-full focus:outline-none focus:ring-2 focus:ring-indigo-500 cursor-pointer'
            trackClassName='bg-gray-50 h-1 rounded-lg'
            min={state.durationMinMax[0]} // min range value
            max={state.durationMinMax[1]} // max range value
            value={state.durationRange} // selected range
            onChange={(value) => {
              // Ensure value is an array with two numbers (min and max range)
              if (Array.isArray(value)) {
                dispatch({ type: 'UPDATE_DURATION', payload: value })
              }
            }}
            renderThumb={(props, state) => (
              <div {...props}>
                <div className='absolute bg-purple-heart -translate-x-1/2 ml-2 text-white rounded-md whitespace-nowrap text-xs font-medium -left-0.5 -top-9 px-3 py-1 after:w-2 after:h-2 after:rotate-45 after:bg-purple-heart after:absolute after:left-1/2 after:-bottom-1/2 after:z-[-1] after:-translate-x-1/2 after:-translate-y-full'>
                  {`${state.valueNow} ${state.valueNow === 1 ? 'week' : 'weeks'}`}
                </div>
              </div>
            )}
          />
        </div>

        {/* Buttons */}
        <div className='flex justify-center mt-6'>
          <button
            onClick={() => dispatch({ type: 'CLEAR_FILTERS' })}
            className='border border-tuna px-5 py-2 rounded-full'
          >
            Clear all filters
          </button>
        </div>
      </div>
    </div>
  )
}
